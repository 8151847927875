import styled from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import tokens from '@veneer/tokens'

export const Container = styled.div`
  background-color: ${tokens.white};
`
export const Spinner = styled.div`
    height: calc(100vh - 190px);
    display: flex;
    align-items: center;
    justify-content: center;
`
