import { authHeader } from './authHeader';
import {getGeminiAbsolutePath} from "./routingHelpers";
import axios from 'axios';

export async function getTenantLocale(stack, acceptLanguage) {
    const authorizationHeader = await authHeader();
    const headers = {
        ...authorizationHeader,
        'Accept-Language': acceptLanguage
    };

    try {
        const locale = await axios.get(getGeminiAbsolutePath(stack, '/api/comfe/v1/tenant/locale'), { headers });

        if (locale && locale.data && locale.data.locale) {
            return locale.data.locale;
        }

        return null;
    } catch(e) {
        return null;
    }
}
