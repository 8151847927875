import React from 'react'
import ComfeLoader from '../src/components/ComfeLoader'
import { ShellProps } from './types/shell'
import projectNames from '../src/configs/projectNames'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type ComfeLoaderProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props: ComfeLoaderProps) {
  // You can find all Shell´s props here. See "./types/shell" for more details.
  // const { v1 } = window.Shell as ShellProps

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ComfeLoader {...props} />
    </section>
  )
}
