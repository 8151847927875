import React, { useEffect, useState } from 'react'
import { loadManifest, unloadManifest } from './loadManifest'
import { getTenantLocale } from '../../helpers/tenantLocale'
import { getManifestUrl } from '../../helpers/manifestUrl'
import { getStack } from '../../helpers/stackHelpers'
import { getPortalLanguage } from '../../helpers/languageHelper'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'

import { Container, Spinner } from './styles'

const ComfeLoader = ({
  appName = 'instantink-comfe',
  globalFunction = 'renderComfe',
  history = null,
  environmentConfig = {
    isJWebAndroidApp: false,
    isJWebDesktopApp: false,
    isJWebiOSApp: false,
    isSmb: true
  },
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let microFrontendScripts = []

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async function loadMicroFrontend() {
      setIsLoading(true)

      const stack = getStack(props.stack);

      const manifest = getManifestUrl(stack)

      let country = 'us'
      let language = 'en'

      const acceptLanguage = getPortalLanguage()

      const tenantLocale = await getTenantLocale(stack, acceptLanguage)

      if (tenantLocale) {
        language = tenantLocale.split('_')[0]
        country = tenantLocale.split('_')[1].toLowerCase()
      }

      const mfeArguments = {
        appName,
        globalFunction,
        history,
        manifest,
        language,
        country,
        environmentConfig,
        setIsLoading
      }

      microFrontendScripts = await loadManifest(mfeArguments)
    }

    loadMicroFrontend()

    return () => {
      unloadManifest(microFrontendScripts)
    }
  }, [])

  return (
    <Container>
      {isLoading ? (
        <Spinner>
          <ProgressIndicator />
        </Spinner>
      ) : (
        <main id={appName} className={appName} />
      )}
    </Container>
  )
}

export default ComfeLoader
